exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biologia-js": () => import("./../../../src/pages/biologia.js" /* webpackChunkName: "component---src-pages-biologia-js" */),
  "component---src-pages-chimica-js": () => import("./../../../src/pages/chimica.js" /* webpackChunkName: "component---src-pages-chimica-js" */),
  "component---src-pages-fisica-js": () => import("./../../../src/pages/fisica.js" /* webpackChunkName: "component---src-pages-fisica-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-matematica-js": () => import("./../../../src/pages/matematica.js" /* webpackChunkName: "component---src-pages-matematica-js" */)
}

